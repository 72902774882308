//let moment = require('moment');
import '../../node_modules/bootstrap-daterangepicker/daterangepicker';

export let datepicker = {
    initDateRange(elStart, elEnd){
        let optionDatepicker = {
            singleDatePicker: true,
            showDropdowns: true,
            locale: {
                format: 'MM/DD/YYYY'
            }
        };
        $(elStart).daterangepicker(optionDatepicker);
        $(elEnd).daterangepicker(optionDatepicker);

        $(elStart).on('apply.daterangepicker', function(ev, picker) {
            optionDatepicker.minDate = picker.startDate;
            $(elEnd).data('daterangepicker').remove();
            $(elEnd).daterangepicker(Object.assign(optionDatepicker, {minDate: $(elStart).val()}));

        });
    }

}
