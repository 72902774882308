/*
//Import TinyMCE
import tinymce from 'tinymce/tinymce';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/image';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/lists';
*/


export let editor = {
    editorInit(selectorId) {
        console.log(10)
        if($(selectorId).length === 0){
            return;
        }
        let urlForLoad = $(selectorId).attr('data-url');
        tinymce.init({
            height: 500,
            selector: selectorId,
            t4cleanup: false,
            // skin: false,
            // content_css: '/plugins/content.min.css',
            fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt 40pt 50pt 60pt",
            toolbar: 'undo redo code | numlist bullist | sizeselect fontselect textcolor forecolor backcolor fontsizeselect styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent',
            images_upload_url: urlForLoad,
            plugins: "advlist autolink lists link image charmap print preview anchor",
            textcolor_rows: "4",
            textcolor_map: [
                "000000", "Black", "993300", "Burnt orange", "333300", "Dark olive", "003300", "Dark green", "003366",
                "Dark azure", "000080", "Navy Blue", "333399", "Indigo", "333333", "Very dark gray", "800000", "Maroon",
                "FF6600", "Orange", "808000", "Olive", "008000", "Green", "008080", "Teal", "0000FF", "Blue", "666699",
                "Grayish blue", "808080", "Gray", "FF0000", "Red", "FF9900", "Amber", "99CC00", "Yellow green", "339966",
                "Sea green", "33CCCC", "Turquoise", "3366FF", "Royal blue", "800080", "Purple", "999999", "Medium gray",
                "FF00FF", "Magenta", "FFCC00", "Gold", "FFFF00", "Yellow", "00FF00", "Lime", "00FFFF", "Aqua", "00CCFF",
                "Sky blue", "993366", "Red violet", "FFFFFF", "White", "FF99CC", "Pink", "FFCC99", "Peach", "FFFF99",
                "Light yellow", "CCFFCC", "Pale green", "CCFFFF", "Pale cyan", "99CCFF", "Light sky blue", "CC99FF", "Plum"
            ],
            init_instance_callback: function(editor) {
                editor.on('ExecCommand', function(e) {
                    jQuery("iframe").contents().find('changeAbleContent').html(editor.getContent());
                    editor.save();
                });
            },
            setup:function(ed) {
                ed.on('init', function()
                {
                    this.execCommand("fontName", false, "Arial");
                    this.execCommand("fontSize", false, "24pt");
                });
                ed.on('keyup', function(e) {
                    jQuery("iframe").contents().find('changeAbleContent').html(ed.getContent());
                    ed.save();
                });
            }
        });
    },
    initEditorSimple(selectorId) {
        if($(selectorId).length === 0){
            return;
        }
        let urlForLoad = $(selectorId).attr('data-url');
        tinymce.init({
            height: 500,
            menubar: false,
            selector: selectorId,
            t4cleanup: false,
            plugins: 'advlist lists',
            toolbar: 'undo redo | numlist bullist | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | outdent indent ',
            content_style: 'body { font-family: inherit; font-size: 14px; }',
            init_instance_callback: function(editor) {
                editor.on('ExecCommand', function(e) {
                    jQuery("iframe").contents().find('changeAbleContent').html(editor.getContent());
                    editor.save();
                });
            },
            setup:function(ed) {
                ed.on('keyup', function(e) {
                    $('body')[0].dispatchEvent(new CustomEvent("changeEditor", {
                        detail: { val: ed.getContent() }
                    }));
                    ed.save();
                });
                ed.on('change', function(e) {
                    $('body')[0].dispatchEvent(new CustomEvent("changeEditor", {
                        detail: { val: ed.getContent() }
                    }));
                    ed.save();
                });
            }
        });
    }
}
