export let alert = {
    showSuccess(txt) {
        let $cnt = $('.alert-section');
        let alertHtml = `<div class="card card-success">
            <div class="card-header">
                <h3 class="card-title">Success</h3>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
            ${txt}
            </div>
        </div>`;
        $cnt.append(alertHtml);
        $('.card-success').slideDown();

        // hides showed alert in 500 ms
        setTimeout(function() {
            $cnt.find('.card-success').first().slideUp(500, function() {
                $(this).remove();
            });
        }, 2000);
    },
    showError(txt) {
        let $cnt = $('.alert-section');
        let alertHtml = `<div class="card card-danger">
            <div class="card-header">
                <h3 class="card-title">Error</h3>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
            ${txt}
            </div>
        </div>`;
        $cnt.append(alertHtml);
        $('.card-danger').slideDown();

        // hides showed alert in 500 ms
        setTimeout(function() {
            $cnt.find('.card-danger').first().slideUp(500, function() {
                $(this).remove();
            });
        }, 2000);
    }
}
