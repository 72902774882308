import { editor } from  '../libs/editor';
import { alert } from  './alert';
import { datepicker } from  './datepicker';
import '../../node_modules/@eastdesire/jscolor/jscolor';
import '../libs/spinner';
import { Sendform } from '../libs/sendform/sendform2';

import '../../node_modules/jquery-ui-dist/jquery-ui.min.js';

import '../../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../../node_modules/bootstrap/dist/js/bootstrap';

import select2 from 'select2';

import { ui } from './ui.js';

$( document ).ready(function() {
    global.app = new App();
    global.app.init();
});

let $body = $('body');
class App{
    constructor(){
        this.editorPlugin = editor;
        this.alertInfo = alert;
    }

    init() {

        console.log(3)

        let _this = this;
        ui.onChangeCategory('.js-select2_category');
        editor.editorInit('#content-editor');
        editor.editorInit('.form-editor');
        editor.initEditorSimple('#content');
        datepicker.initDateRange('.js_date-start', '.js_date-end')
        this.initToggleStatusSelfHelpScripts();
        this.copyCode();
        this.initChangeColor();
        this.initCreateBtnInPreview();
        ui.activeHashTab();
        ui.approvingStatusPoints('.js_approve-points', '.js_approve-form', 2);
        ui.approvingStatusPoints('.js_cancel-points', '.js_approve-form', 3);
        ui.onResetForm('.js_reset-form', '.js_send-form-password');
        ui.addTagOnCreatePoint('.js_form-create-point');
        //Show Rating Modal
        ui.showRatingModals('__has-modal', '#modalRatingInfo');
        ui.getRatingModalCnt('#modalRatingInfo');
        ui.openRatingModal('#modalRatingInfo');

        ui.dragAndDropEl('.js_draggable-el', '.js_droppable-blk');
        //game pages
        $('.js-select2').select2({minimumResultsForSearch: Infinity});
        this.initSelect();
        this.initConfirmBtn();
        this.initConfirmModal();
        this.toggleFilterGame();

        ui.setDefaultDateTime('.js_datetime');
        //ui.setDateTime('.js_datetime-val');

        //Create rebuttal folder
        ui.createEntity('.js_create-folder-btn', '.js_create-folder-form', '#modalRebuttalFolder')
        ui.createEntity('.js_create-btn', '.js_create-form', '#modalRebuttalFolder')
        ui.createEntity('.js_create-tree-btn', '.js_create-tree-form', '#modalAddTree')
        ui.createEntity('.js_create-script-btn', '.js_create-script-form', '#modalAddScript')
        ui.createEntity('.js_create-self-btn', '.js_create-self-form', '#modalRebuttalFolder')
        ui.createEntity('.js_create-team-btn', '.js_create-team-form', '#modalAddTeam')
        ui.createEntity('.js_edit-team-btn', '.js_edit-team-form', '#modalEditTeam')
        ui.openCreateFolderModal('#modalRebuttalFolder')
        //Delete folder/rebuttal
        ui.openDeleteFolder('.js_open-modal-delete', '#modalDelete')
        ui.deleteFolderModal('.js_delete-folder-btn', '.js_modal-form', '#modalDelete')
        //Create rebuttal
        ui.createEntity('.js_create-rebuttal-btn', '.js_create-rebuttal-form', '#modalCreateRebuttal')
        ui.openCreateRebuttalModal('#modalCreateRebuttal')
        ui.openEditRebuttalModal('#modalEditRebuttal')
        ui.openAddTeamModal('#modalAddTeam')
        //user
        this.getModalContent($('.js_edit-user').attr('data-action'), $('.js_user-edit-cnt'));

        //Edit rebuttal folder
        $body.on('click', '.js_edit-folder', function(e) {
            e.preventDefault();
            _this.getModalContent($(this).attr('data-action'), $('.js_modal-rebuttal-edit-cnt'));
        })

        ui.createEntity('.js_edit-folder-btn', '.js_edit-folder-form', '#modalRebuttalFolderEdit')
        ui.createEntity('.js_edit-rebuttal-btn', '.js_edit-rebuttal-form', '#modalEditRebuttal')

        $body.on('click', '.js_open-edit-rebuttal', function(e){
            e.preventDefault();
            _this.getModalContent($(this).attr('data-action'), $('.js_modal-edit-rebuttal-cnt'));
        })

        $body.on('click', '.js_add-points', function(e){
            e.preventDefault();
            _this.getModalContent($(this).attr('data-action'), $('.js_add-points-cnt'));
        })
        $body.on('click', '.js_info-statistic', function(e){
            e.preventDefault();
            _this.getModalContent($(this).attr('data-action'), $('.js_info-statistic-cnt'));
        })
        $body.on('click', '.js_points-action', function(e){
            e.preventDefault();
            _this.getModalContent($(this).attr('data-action'), $('.js_points-action-cnt'));
        })
        $body.on('click', '.js_modal-folder-cnt', function(e){
            e.preventDefault();
            _this.getModalContent($(this).attr('data-action'), $('.js_modal-rebuttal-cnt'));
        })
        $body.on('click', '.js_open-edit-team', function(e){
            e.preventDefault();
            _this.getModalContent($(this).attr('data-action'), $('.js_modal-edit-team-cnt'));
        })
        $body.on('click', '.js_filter-points', function (e){
            e.preventDefault();
            let action = $(this).attr('data-action');
            let participant = $('.js_filter-points-participant').val();
            let status = $('.js_filter-points-status').val();
            $.ajax({
                url: action,
                method: 'GET',
                data: {
                    point_status_id: status,
                    participant_id: participant
                },
                success: (data) => {
                    $('.js_filter-points-wrap').html(data)
                }, error:  (er)=>{
                    app.alertInfo.showError(er.responseJSON.message);
                }
            });
        });

        $body.on('click', '.js_delete-points', function (e){
            e.preventDefault();
            let action = $(this).attr('data-action');
            let method = $(this).attr('data-method');
            let user_id = $('.js_user-id').val()
            $.ajax({
                url: `${action}`,
                method: method,
                data: {
                    user_id,
                    _token: _this.getToken()
                },
                success: () => {
                    $('.js_close-btn-modal-points').trigger({ type: "click" });
                    window.location.reload();
                }, error:  (er)=>{
                    app.alertInfo.showError(er.responseJSON.message);
                }
            });
        });

        $body.on('submit', '.js_send-form-password', function (e){
            e.preventDefault();
            let url = $(this).attr('action');
            let method = $(this).attr('method');
            let data = $(this).serialize();
            $.ajax({
                url: url,
                method: method,
                data: data,
                success: () => {
                    $(".js_close-btn-modal-password-user").trigger({ type: "click" });
                    app.alertInfo.showSuccess('Password update');
                }, error:  (er)=>{

                    $.each(er.responseJSON.errors, function(key,data) {
                        let $input = $("[name="+key+"]");
                        $input.addClass('is-invalid');
                        let txtEr;
                        $.each(data, function(index,value) {
                            txtEr = ' ' + value;
                        });
                        $input.next('.invalid-feedback').html(txtEr);
                    });
                    app.alertInfo.showError(er.responseJSON.message);
                }
            });

        })
    };

    initSelect() {
        $('.js_select').select2({
            tags: true,
            minimumInputLength: 0,
            createTag: (params) =>{
                console.log(params)
                let term = $.trim(params.term);

                if (term === '') {
                    return null;
                }
                $(this).closest('form').find('#category_name').val(term)

                return {
                    id: term,
                    text: term,
                    newTag: true // add additional parameters
                }
            }
        })
    }
    getModalContent(url, blk, callbackSuccess = null){
        blk.html('');
        $.ajax({
            url: url,
            method: "GET",
            contentType : url,
            data: {},
            success: (html) => {
                blk.html(html);
                $('.js-select2').select2({minimumResultsForSearch: Infinity});
                this.initSelect();
                editor.initEditorSimple('#content');
                if(callbackSuccess){
                    callbackSuccess();
                }
            }, error: (er)=>{
                console.log(er);
            }
        });
    }
    toggleFilterGame(){
        $body.on('change', '.js_scripts-is_active-toggle', function (e){
            let status = $(this).prop('checked');
            let url = $(this).data('url');
            window.location.replace(url + status);
        });
    }

    initConfirmModal(){
        $body.on('click', '.js_modal-confirm-delete', function (e){
            let url = $(this).attr('data-action');
            let redirect = $(this).attr('data-redirect');
            let text = $(this).attr('data-text');
            let ttl = $(this).attr('data-ttl');
            $('.js_confirm-btn').attr('data-action', url).attr('data-redirect', redirect);

            $('.js_modal-ttl-confirm').html(ttl);
            $('.js_modal-text-confirm').html(text);
        });
    }
    initConfirmBtn(){
        $body.on('click', '.js_confirm-btn',  (e)=>{
            let url = $('.js_confirm-btn').attr('data-action');
            let redirect = $('.js_confirm-btn').attr('data-redirect');
            $.ajax({
                url: url,
                method: "DELETE",
                headers: {
                    'X-CSRF-TOKEN': this.getToken()
                },
                contentType : url,
                success: (data) => {
                    $(".js_close-modal-delete-confirm").trigger({ type: "click" });
                    app.alertInfo.showSuccess(data.message);
                    window.location.replace(redirect);
                },
                error: (er)=>{
                    window.location.replace(redirect);
                }
            });
        });
    }

    //for self help scripts page
    initToggleStatusSelfHelpScripts(){
        let _this =this;
        $body.on('change', '.js_scripts-status-toggle', function (e){
            let status =  $(this).prop( "checked" );
            let action = $(this).attr('data-url');
            $.spinnerAdd();
            $.ajax({
                url: action,
                method: "POST",
                headers: {
                    'X-CSRF-TOKEN': _this.getToken()
                },
                data: {
                    status: status
                },
                success: (data) => {
                    $.spinnerRemove();
                    app.alertInfo.showSuccess('Status update!')
                }, error: (er) =>{
                    $.spinnerRemove();
                    app.alertInfo.showError(er.message)
                }
            });
        });
    }
    getToken(){
        return $( "[name='csrf-token']" ).attr('content');
    }
    copyCode(){
        $body.on('click', '.js-copy-iframe', function (e){
            e.preventDefault();
            let link = $(this).attr('data-url')
            let code = `<iframe style="width: 100%; border: none;"
                        onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"
                        frameborder="0" scrolling="no"
                        src="${link}"></iframe>`;

            let $tempInput =  $("<textarea>");
            $body.append($tempInput);
            $tempInput.val(code).select();
            document.execCommand("copy");
            $tempInput.remove();

            app.alertInfo.showSuccess('Code copied!')
        })
    }


    //color picker
    initChangeColor(){
        let _this = this;
        $body.on('change','.js_fonts', function (e){
            let font = $(this).val();
            $("#iframe-preview").contents().find('.self-help-script').css({'font-family':font});
        });
        $body.on('change','.js-color', function (e){
            let curColor = $(this).val();
            let name = $(this).attr('data-class');
            _this.changeColor(curColor, name);
        });
        $body.on('change','.js-color-background', function (e){
            let curColor = $(this).val();
            let name = $(this).attr('data-class');
            _this.changeBackground(curColor, name);
        });
        $body.on('change','.js-color-btn', function (e){
            let curColor = $(this).val();
            let name = $(this).attr('data-class');
            _this.changeBackground(curColor, name);
        });
    }
    changeColor(color, elClass){
        $("#iframe-preview").contents().find(elClass).css({color: color});
    }
    changeBackground(color, elClass){
        $("#iframe-preview").contents().find(elClass).css({'background-color': color});
    }
    initCreateBtnInPreview(){
        $('.js-btn-preview-text').keyup( () =>{
            this.appendBtnPreview();
        });
    }
    appendBtnPreview(){
        let valInput = $('.js-btn-preview-text').val();
        if(valInput === ''){
            $("#iframe-preview").contents().find('.new-btn').remove();
            return;
        }
        let $containerBtn = $("#iframe-preview").contents().find('.self-help-script-row');
        let newBtn = $containerBtn.find('.new-btn');
        if(newBtn.length){
            newBtn.text(valInput)
        }else{
            let htmlBtn =`<a href="#" class="self-help-script-btn new-btn">${valInput}</a>`;
            $containerBtn.append(htmlBtn);
        }
    }

};

