import {editor} from "../libs/editor";

let $body = $('body');

let statusModal = true;

export let ui = {

    activeHashTab() {
        let hash = window.location.hash;
        let navTabs = $('.games-tab a');
        let navTabsCnt = $('.games-tab-pane .tab-pane');
        $body.on('click', '.games-tab a', function(e) {
            e.preventDefault()
            let currUrl = $(this).attr('data-value')
            window.location.href = window.location.origin + window.location.pathname + currUrl
        })
        if (hash) {
            navTabs.each((i, el) => {
                $(el).removeClass('active')
            })
            navTabsCnt.each((i, el) => {
                $(el).removeClass('active show')
            })
            $('.games-tab a[href="' + hash + '"]').addClass('active');
            $('.games-tab-pane .tab-pane' + hash + '').addClass('active show');
        }
    },

    addTagOnCreatePoint(form){
        $('#modalAddPointer').on('shown.bs.modal', function () {
            let $form = $(form);
            let url = $form.attr('action')
            let hash = window.location.hash;
            $form.attr('action', url+hash);
        })
    },

    approvingStatusPoints(btn, form, statusId){
        $body.on('click', btn, function(e) {
            e.preventDefault();
            $('.js_change-status').val(statusId);
            let url = $(this).attr('data-action');
            let method = $(this).attr('data-method');
            let data = $(form).serialize();
            $.ajax({
                url: url,
                method: method,
                data: data,
                success: () => {
                    $('#modalActionPoints').modal('hide');
                    window.location.reload();
                }
            })
        })
    },

    onResetForm(btn, form){
        $body.on('click', btn, () => {
            this.resetForm($(form));
        })
    },

    resetForm(form){
        form.trigger("reset");
        let $inp = form.find('.form-control');
        $.each($inp, function(i, el) {
            $(el).removeClass('is-invalid');
            $(el).closest('.form-group').find('.invalid-feedback').html('');
        })
    },

    showRatingModals(activeClass, modal) {
        if ($body.hasClass(activeClass)) {
            let params = new window.URLSearchParams(window.location.search);
            if (params.get('notification') !== null) {
                setTimeout(() => {
                    $(modal).modal('show');
                }, 300000);
            } else {
                $(modal).modal('show');
            }
        }
    },

    getRatingModalCnt(modal, dataCnt = null) {
        $(modal).on('shown.bs.modal', function() {
            if (statusModal) {
                let url = $(this).data('url');
                console.log(url)
                $.ajax({
                    url: url,
                    method: 'GET',
                    data: {},
                    success: (data) => {
                        statusModal = false;
                        if (data.modal === false) {
                            $(modal).modal('hide');
                        } else {
                            $('.js_rating-modal-cnt').html(data);
                            $('.js-select2').select2({minimumResultsForSearch: Infinity});
                        }
                    }, error: (err) => {
                        console.log(err);
                    }
                })
            } else {
                $('.js_rating-modal-cnt').html(dataCnt);
                $('.js-select2').select2({minimumResultsForSearch: Infinity});
            }
        })
    },

    openRatingModal(modal){
        let _this = this;
        $(modal).on('hidden.bs.modal', function (e) {
            let closeUrl = $(this).find('.js_close-btn-modal-create').attr('href');
            $.ajax({
                url: closeUrl,
                method: 'GET',
                data: {},
                success: (data) => {
                    if (data.modal === false) {
                        $(modal).modal('hide');
                    } else {
                        $(modal).modal('show');
                        _this.getRatingModalCnt(modal, data);
                    }
                }, error: (err) => {
                    console.log(err);
                }
            })
        })
    },

    /**
     * Drag&Drop element
     * @param el
     * @param blk
     */
    dragAndDropEl(el, blk) {
        $(el).draggable({
            snap: blk,
            snapMode: 'inner',
            snapTolerance: 10,
            axis: 'y',
            cursor: 'move',
            revert: 'invalid',
            evertDuration: 1,
            zIndex: 100
        });
        $(blk).droppable({
            tolerance: "pointer",
            drop: function(event, ui) {
                ui.draggable.draggable('disable')
                let url = $(this).data('action');
                let dragObj = {
                    type_id: $(this).data('type-id'),
                    folder_id: $(this).data('folder-id'),
                    model_id: ui.draggable.data('model-id'),
                    _token: app.getToken()
                }
                $.ajax({
                    url: url,
                    method: 'POST',
                    data: dragObj,
                    success: () => {
                        window.location.reload();
                    }
                })
            }
        });
    },

    createEntity(btn, form, modal){
        $body.on('click', btn, function(e) {
            e.preventDefault();
            let url = $(form).attr('action');
            let method = $(form).attr('method');
            let data = $(form).serialize();
            $.ajax({
                url: url,
                method: method,
                data: data,
                success: () => {
                    $(modal).modal('hide');
                    window.location.reload();
                }, error: (err) => {
                    $.each(err.responseJSON.errors, function(key,data) {
                        let $input = $("[name="+key+"]");
                        $input.addClass('is-invalid');
                        let txtEr;
                        $.each(data, function(index,value) {
                            txtEr = ' ' + value;
                        });
                        $input.closest('.form-group').find('.invalid-feedback').html(txtEr);
                    });
                }
            })
        })
    },

    openCreateFolderModal(modal) {
        $(modal).on('shown.bs.modal', () => {
            this.resetForm($(modal).find('form'))
        })
    },

    openDeleteFolder(btn, modal) {
        $body.on('click', btn, function(e) {
            e.preventDefault();
            let url = $(this).data('action');
            let modalTtl = $(this).data('ttl');
            let modalCnt = $(this).data('cnt');
            ui.getDeleteModalCnt(modal, url, modalTtl, modalCnt)
        })
    },

    getDeleteModalCnt(modal, url, modalTtl, modalCnt) {
        $(modal).on('shown.bs.modal', function() {
            $(this).find('.js_modal-ttl').html(modalTtl)
            $(this).find('.js_modal-cnt').html(modalCnt)
            $(this).find('.js_modal-form').attr('action', url)
        })
    },

    deleteFolderModal(btn, form, modal){
        $body.on('click', btn, function(e) {
            e.preventDefault();
            let url = $(form).attr('action');
            let method = $(form).attr('method');
            let data = $(form).serialize();
            $.ajax({
                url: url,
                method: method,
                data: data,
                success: () => {
                    $(modal).modal('hide');
                    window.location.reload();
                }, error: (err) => {
                    $.each(err.responseJSON.errors, function(key,data) {
                        let $input = $("[name="+key+"]");
                        $input.addClass('is-invalid');
                        let txtEr;
                        $.each(data, function(index,value) {
                            txtEr = ' ' + value;
                        });
                        $input.next('.invalid-feedback').html(txtEr);
                    });
                }
            })
        })
    },

    openCreateRebuttalModal(modal) {
        $(modal).on('shown.bs.modal', function() {
            let form = $(this).find('form')
            form.find('input[name="title"]').val('');
            let $inp = form.find('.form-control');
            $.each($inp, function(i, el) {
                $(el).removeClass('is-invalid');
                $(el).closest('.form-group').find('.invalid-feedback').html('');
            })
            form.find('.js_select').select2({
                tags: true,
                minimumInputLength: 0,
                data: function (term, page) {
                    return {
                        q: term, // search term
                    };
                },
                createTag: (params) =>{
                    let term = $.trim(params.term);

                    if (term === '') {
                        return null;
                    }

                    form.find('#category_name').val(term)

                    return {
                        id: term,
                        text: term,
                        newTag: true // add additional parameters
                    }
                }
            })/*.on("select2:select", function(e){
                console.log(e)
                if ($(this).find(':selected').is('[data-select2-tag]')) {
                    console.log(1)
                    let data = e.params.data;
                    form.find('#category_name').val(data.text)
                } else {
                    console.log(2)
                    form.find('#category_name').val(0);
                }
            });*/

            editor.editorInit('#content-editor-script');

            form.find('textarea').each(function(k, v) {
                tinyMCE.get(k).setContent('');
            });
        })
    },

    openEditRebuttalModal(modal) {
        $(modal).on('shown.bs.modal', function() {
            tinymce.remove()
            editor.editorInit('#content-editor-script-edit');
            let form = $(this).find('form')
            form.find('.js_select').select2({
                tags: true,
                minimumInputLength: 0,
                data: function (term, page) {
                    return {
                        q: term, // search term
                    };
                },
                createTag: (params) =>{
                    let term = $.trim(params.term);

                    if (term === '') {
                        return null;
                    }

                    form.find('#category_name').val(term)

                    return {
                        id: term,
                        text: term,
                        newTag: true // add additional parameters
                    }
                }
            })
        })
    },

    /**
     * On change categories to filter Rebuttals
     * @param el
     */
    onChangeCategory(el) {
        $(el).select2({minimumResultsForSearch: Infinity})
            .on('select2:select', function() {
                ui.updateUrlFilter(this)
            })
            .on('select2:unselect', function() {
                ui.updateUrlFilter(this)
            })
    },

    /**
     * Update url with categories for filter Rebuttals
     * @param el
     */
    updateUrlFilter(el) {
        let $categories = $(el).val();
        let url = $(el).data('action');
        let urlCat = '';
        if ($categories.length !== 0) {
            $.each($categories, function(i, el) {
                urlCat+=`${el}%2C`
            })
            if (new URL(url).search) {
                window.location.replace(`${url}&categories=${urlCat}`);
            } else {
                window.location.replace(`${url}/?categories=${urlCat}`);
            }
        } else {
            window.location.replace(url);
        }
    },

    openAddTeamModal(modal) {
        $(modal).on('shown.bs.modal', () => {
            this.resetForm($(modal).find('form'))
            $(modal).find('.js-select2').select2('destroy');
            $(modal).find('.js-select2').select2({minimumResultsForSearch: Infinity});
        })
    },

    setDefaultDateTime(inp) {
        let $inp = $(inp);
        $.each($inp, function(i, el) {
            let curStartDate = $(el).val();
            if (curStartDate === 'null') {
                curStartDate = new Date();
            }
            ui.initDateTime(el, curStartDate)
        })
    },

    initDateTime(inp, date) {
        $(inp).daterangepicker({
            timePicker: true,
            singleDatePicker: true,
            startDate: date,
            locale: {
                lang: 'en',
                format: 'MM/DD/YYYY hh:mm A'
            }
        });
    }

}
